body {
    background-color: #F5F5F5;
}
.active-link {
    color: #73BA9B;
    text-decoration: underline;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #73BA9B;
    background-color: #ffffff;
}
.nav-link {
    color: #01110A;
    transition: color 0.1s ease-in-out;
}
.nav-link:hover {
    padding: 10px;
    border-radius: 10px;
    transform: scale(2.0);
    color: #73BA9B;
    background-color: rgba(115,186,155,0.1);
}
.send-btn {
    color: #01110A;
    padding: 15px;
    border-radius: 10px;
    border: 2px solid #01110A;
    display: flex;
    align-items: center;
    font-size: 20px;
    background-color: #ffffff;
}
.send-btn:hover {
    color: #73BA9B;
    border: 2px solid #73BA9B;
    cursor: pointer;
    background-color: rgba(115,186,155,0.1);
    transition: color 0.1s ease-in-out;
}
.more-info-btn {
    background-color: #73BA9B;
    padding: 10px 50px 10px 50px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    font-size: 20px;
    border: 2px solid #73BA9B;
}
